"use client";

import { ErrorPage, ErrorPageProps } from "@yardzen/components/error-page";
import { redirect, usePathname, useRouter } from "next/navigation";

export default function Error({ error, reset }: ErrorPageProps) {
  const router = useRouter();
  const path = usePathname();

  if (
    !/(\/login|\/sign-up|\/forgot-password|\/reset-password|\/style-finder|\/select-project-elements)/.test(
      path,
    ) &&
    /"code":"UNAUTHENTICATED"/.test((error as any).message ?? "")
  ) {
    router.push(
      "/login?callback_url=" + encodeURIComponent(window.location.pathname),
    );
    return null;
  }

  return <ErrorPage error={error} reset={reset} />;
}
