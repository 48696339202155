"use client"; // Error components must be Client Components

import { startFrontEndTracing } from "@yardzen/next-client-util";
import { PageError } from "@yardzen/ui";
import { useEffect } from "react";

export interface ErrorPageProps {
  error: Error;
  reset: () => void;
}

if (typeof window !== "undefined") {
  startFrontEndTracing(`client-web`); // TODO: fix this so that the service name is what you would expect
}

export function ErrorPage({ error, reset }: ErrorPageProps) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <PageError
      title="We're sorry, but something went wrong."
      description="Please try again or contact us for assistance."
      displayContactInfo={true}
      allowRefreshButton={true}
    />
  );
}
